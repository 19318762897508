<button *ngIf="account.isLoggedIn" (click)="open()" class="user-photo" referrerpolicy=“no-referrer” style.backgroundImage="url({{account.avatarUrl}})"></button>

<ng-template #menuContent>
  <div class="account-menu-container mat-elevation-z8">
    <div class="user-photo">
      <img referrerpolicy=“no-referrer” [src]="account.avatarUrl" alt="avatarUrl">
    </div>

    <div class="title-row">
      <span class="title">{{ account.displayName || 'Welcome!' }}</span>
      <button class="expand-button" mat-icon-button (click)="providerPanel.toggle()" *ngIf="account.isLoggedIn">
        <mat-icon>{{providerPanel.expanded ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </div>
    <ng-container cdkAccordionItem #providerPanel="cdkAccordionItem">
      <div *ngIf="providerPanel.expanded" class="provider-panel">
        <div class="provider-row">
          <img class="provider-icon" src="http://google.com/favicon.ico" alt="google icon"/>
          <span class="provider-email">{{ account.googleInfo?.email || "Unknown" }}</span>
        </div>
        <div class="provider-row">
          <img class="provider-icon" src="http://github.com/favicon.ico" alt="github icon"/>
          <span class="provider-email">{{ account.githubInfo?.email || "Account Not Linked" }}</span>
          <button matTooltip ="Link Github account" *ngIf="account.githubInfo === null" mat-icon-button class="link-provider-account"
            (click)="account.linkWithGithub()">
            <mat-icon>add_link</mat-icon>
          </button>
          <button matTooltip="Unlink Github account" *ngIf="account.githubInfo" mat-icon-button class="link-provider-account"
            (click)="account.unlinkFromGithub()">
            <mat-icon>link_off</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <div class="projected-content">
      <!--
        This projected content allows for an application to provide applicatoin specific content or options
        within the account menu popup.
      -->
      <ng-content></ng-content>
    </div>

    <div class="bottom-row">
      <span class="spacer"></span>
      <button *ngIf="!account.isLoggedIn" mat-stroked-button color="primary" (click)="account.signInWithGoogle()">Sign
        In</button>
      <button *ngIf="account.isLoggedIn" mat-stroked-button color="primary" (click)="account.signOut()">Sign
        Out</button>
    </div>
  </div>
</ng-template>