// TODO: allow for the production option to be set during the build process.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyDM3rXWUgYuxYCmBKwnZvvnraYoYIE5_5U',
    authDomain: 'internal-200822.firebaseapp.com',
    projectId: 'internal-200822',
    storageBucket: 'internal-200822.appspot.com',
    messagingSenderId: '823469418460',
    appId: '1:823469418460:web:009b51c93132b218761119',
  },
  production: false,
};
